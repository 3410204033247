<template>
  <div class="contact" role="main">

    <div class="mainimage">
      <h1 class="text-center">{{languageData.title}}</h1>
    </div>

    <b-container fluid>
      <div class="section-background"></div>

        <b-col class="container">
          <b-form id="contactUs" class="contactUs" name="contactUs" @submit="submitContact" v-if="showForm">
            <div class="form-header">
              <h1>{{languageData.form.title}}</h1>
              <p>{{languageData.form.message}}<br/></p>
              <p class="small"><em>{{languageData.form.all_fields_required}}</em><br/></p>
            </div>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group>
                <label for="firstName">{{languageData.form.first_name}}</label>
                <b-form-input id="firstName" v-model="form.firstName" type="text" required></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group>
                <label for="lastName">{{languageData.form.last_name}}</label>
                <b-form-input id="lastName" v-model="form.lastName" type="text" required></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group>
                <label for="email">{{languageData.form.email}}</label>
                <b-form-input id="email" v-model="form.email" type="email" required></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group>
                <label for="phoneNumber">{{languageData.form.phone}}</label>
                <b-form-input id="phoneNumber" v-model="form.phoneNumber" type="text" required></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="12">
              <b-form-group>
                <label for="subject">{{languageData.form.subject}}</label>
                <b-form-input id="subject" v-model="form.subject" type="text" required></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="12">
              <b-form-group>
                <label for="comments">{{languageData.form.help}}</label>
                <b-form-textarea id="comments" v-model="form.comments" rows="3" required></b-form-textarea>
              </b-form-group>
            </b-col>

            <b-form-group  class="form-buttons col-sm-12">
              <button type="submit" class="btn gy-primary" v-if="!loading">{{languageData.form.btn_title}}</button>
              <b-spinner :label="languageSharedData.loading" v-if="loading"></b-spinner>
             </b-form-group >      
            </b-row>
          </b-form>

          <div class="gy-form-ty" v-if="showThanks && !showForm">
            <h2>{{languageData.thank_you}}</h2>
            <p>{{languageData.complete_message}}</p>
          </div>

          <div class="gy-form-fail" v-if="showError && !showForm">
            <h2>{{languageSharedData.error_occured}}</h2>
            <p>{{languageSharedData.try_again}}</p>
          </div>
        </b-col>

    </b-container>
  </div>
</template>

<script>

  import dataService from "../services/data.service";

  window.dataLayer = window.dataLayer || []

  export default {
    name: 'Contact',
    metaInfo () {
      return {
        title: this.languageData.page_title + ' | ' + this.$store.getters["appName"]
      }
    },
    data() {
      return {
        form: {
          firstName: '',
          lastName: '',
          email: this.$store.state.user.email,
          phoneNumber: '',
          subject: '',
          comments: ''
        },
        showForm: true,
        invalidField: ' ',
        showThanks: false,
        showError: false,
        loading: false
      }
    },
    methods: {
      async submitContact (evt) {
        evt.preventDefault()
        this.loading = true
        try {
          let response = await dataService.sendContactUs(this.form)
          this.loading = false
          this.showForm = false
          if (response.status == 200) {
            this.showThanks = true
            window.dataLayer.push({ 'event': 'formSubmitted', 'formName': 'Contact Us' })
          } else {
            this.showError = true
          }
        } catch {
            this.loading = false
            this.showError = true
        }
      }
    },
    computed: {
      languageSharedData: function () {
        return this.$store.state.lang.languageData['shared'][this.$store.state.lang.language];
      },
      languageData: function () {
        return this.$store.state.lang.languageData[this.$route.name.toLowerCase()][this.$store.state.lang.language];
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .contact {
    background-color: #f1f1f1;
    background-image: url(../assets/images/background/diagonal-lines.png);
    background-repeat: repeat;
  }
  .btn {
    margin-top: 30px;
    margin-left: 0px;
  }
  .custom-select {
    margin-bottom: 15px
  }
  .form-control {
    margin-bottom: 15px;
  }
  .contactform {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
    background-color: #fff;
  }
  .contactform.container {
    width: 80%;
  }
  .contactform label {
    margin-bottom: 0px;
  }
  .desc {
    text-align: center;
    padding-top: 20px;
    margin-bottom: 0px;
  }
  .mainimage h1 {
      margin-top: 0;
      text-transform: uppercase;
      padding: 7px;
      border: 7px solid #cdcdcd;
      display: inline-block;
      background-color: rgba(255,255,255,0.8);
  }
  .mainimage {
    min-height: 250px;
    padding-top: 75px;
    text-align: center;
    background-image: url(../assets/images/contact-md.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .contactform h2 {
    font-weight: 700;
  }
  .login {
    margin-top: 40px;
    margin-bottom: 20px;
    min-height: 400px;
  }
  .form-header {
    margin-bottom: 30px;
  }
  .form-row {
    margin-top: 15px;
  }
  .contact {
    background-color: #ccc;
    background-repeat: repeat;
  }
  .container {
    background-color: #fff;
    min-height: 500px;
  }
  .form-header h1 {
    color: #274ba7;
    font-size: 42px;
    margin-bottom: 0px;
  }
  select {
    border-radius: 0px;
  }
  .btn {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 35px;
  }
  .other-contact {
    margin-top: 10px;
    margin-bottom: 25px;
    float: left;
  }
  .other-contact h4 {
    margin-bottom: 0px;
  }

/*Media Queries Start*/
 @media (max-width: 500px) {
    .mainimage {
      background-image: url('../assets/images/contact-xs.jpg');
    }
  }
  @media (min-width: 501px) and (max-width: 768px) {
    .mainimage {
      background-image: url('../assets/images/contact-sm.jpg');
    }
  }
  @media (max-width: 768px) {
    .container.contacts {
      padding-top: 25px;
    }
  }
  @media (min-width: 768px) {
    .container {
      padding: 40px;
      min-height: 635px;
    }
    .btn {
      width: 250px;
    }
  }
   @media (max-width: 810px) {
    .container.contacts {
      min-height: 100px;
    }
  }
  @media (min-width: 810px) {
    .container {
      max-width: 1140px;
    }
  }
  @media (min-width:992px) {
    .container {
      min-height: 500px;
    }
  }
  @media (max-width: 1220px) {
    .container.contacts {
      background-color: #f1f1f1;
    }
  }
  @media (min-width: 1281px) and (max-width: 1600px) {
    .mainimage {
      background-image: url('../assets/images/contact-lg.jpg');
    }
  }
  @media (min-width: 1601px) {
    .mainimage {
      background-image: url('../assets/images/contact-xl.jpg');
    }
  }
 
</style>
