<template>
  <div class="global-header">
    <b-navbar class="all-sites-nav">
      <b-container>
        <b-navbar-nav class="ml-auto">
          <b-nav-item v-if="$store.state.lang.language == 'fr-CA'" @click="changeLanguage('en-CA')"><b-icon-globe></b-icon-globe> English</b-nav-item>
          <b-nav-item v-if="$store.state.lang.language == 'en-CA'" @click="changeLanguage('fr-CA')"><b-icon-globe></b-icon-globe> Français</b-nav-item>

          <b-nav-item right v-if="$store.state.user.auth" v-on:click="$msal.signOut()" class="ylw">
            <b-icon-lock-fill></b-icon-lock-fill>
            {{languageSharedData.sign_out}}
          </b-nav-item>
          <b-nav-item right v-if="!$store.state.user.auth" v-on:click="$msal.signIn()" class="ylw">
            <b-icon-lock-fill></b-icon-lock-fill>
            {{languageSharedData.sign_in}}
          </b-nav-item>
        </b-navbar-nav>
      </b-container>
    </b-navbar>
    <b-navbar toggleable="lg">
      <b-container id="gynav">

        <b-navbar-brand class="gohome" :to="{name: 'Login', params: {lang:$store.state.lang.language}}"><img class="logo" src="../assets/images/goodyear-car-dealers-logo.png" :alt="languageSharedData.nav_cdpLogo"/></b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav v-if="$store.state.user.auth">
          <b-navbar-nav class="main">
            <!-- make these dynamic with store or the langSelected -->
            <b-nav-item :to="{name: 'Dashboard', params: {lang:$store.state.lang.language}}" class="first">{{languageSharedData.nav_link_dashboard}}</b-nav-item>
            <b-nav-item :to="{name: 'Resources', params: {lang:$store.state.lang.language}}">{{languageSharedData.nav_link_resources}}</b-nav-item>
            <b-nav-item :to="{name: 'Contact', params: {lang:$store.state.lang.language}}">{{languageSharedData.nav_link_contact}}</b-nav-item>
            <b-nav-item-dropdown v-if="$store.getters.isUserAdmin" :text="languageSharedData.nav_link_admin" right>
              <b-dropdown-item :to="{name: 'Profile', params: {lang:$store.state.lang.language}}">{{languageSharedData.nav_link_profile}}</b-dropdown-item>
              <b-dropdown-item :to="{name: 'PendingUsers', params: {lang:$store.state.lang.language}}">{{languageSharedData.nav_link_pending_users}}</b-dropdown-item>
              <b-dropdown-item :to="{name: 'NonsigChanges', params: {lang:$store.state.lang.language}}">{{languageSharedData.nav_link_nonsig_changes}}</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>

      </b-container>
    </b-navbar>
  </div>
</template>

<script>
  export default {
    name: 'Header',
    data () {
      return {
        windowWidth: 0
      }
    },
    mounted () {
      //pass to backend API 
      //Make page names Uppercase
      this.$nextTick(async function () {
        window.addEventListener('resize', this.getWindowWidth)
        // Init
        this.getWindowWidth()
      })
    },
    methods: {
      changeLanguage (newLanguage) {
        this.$store.commit('lang/changeLanguage', newLanguage)
        //reload current path with same query string, same params except updated lang param
        const params = { ...this.$route.params, lang: newLanguage }
        const query = { ...this.$route.query }
        this.$router.push({params: params, query: query})
      },
      getWindowWidth () {
        this.windowWidth = document.documentElement.clientWidth

        // small screen
        if (this.windowWidth <= 1200) {
          document.getElementById('gynav').classList.add('container-fluid')
          document.getElementById('gynav').classList.remove('container')
        } else {
          document.getElementById('gynav').classList.add('container')
          document.getElementById('gynav').classList.remove('container-fluid')
        }
      },
      beforeDestroy () {
        window.removeEventListener('resize', this.getWindowWidth)
      }
    },
    computed: {
      languageSharedData: function () {
        return this.$store.state.lang.languageData['shared'][this.$store.state.lang.language];
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .nav-item.all-sites-nav.second-link {
    border-left: 1px solid hsla(0,0%,100%,.2);
  }
  .navbar-nav.toplinks {
    margin-right: 33%
  }
  .all-sites-nav.navbar {
    background-color: #1e468d;
    color: #fff;
    height: 34px;
  }
  .navbar {
    color: #fff;
    background-color: #274ba7;
    padding: .2rem .2rem .2rem 1rem;
    z-index: 3;
  }
  .global-header :focus {
    outline: none;
  }
  .navbar-brand {
    font-family: "Barlow", sans-serif;
  }
  ::v-deep .all-sites-nav.navbar .navbar-nav .nav-item a.nav-link {
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    letter-spacing: 0px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-bottom: 0px;
  }
  ::v-deep .navbar .navbar-nav .nav-item a.nav-link {
    color: #fff;
    font-size: 16px;
    font-family: "Barlow", sans-serif;
    padding: 3px 10px;
    text-transform: uppercase;
    margin: 0px;
  }
  .navbar .navbar-nav .nav-item a.nav-link.ylw { 
    color: #fd0;
  }
  ::v-deep .all-sites-nav.navbar .navbar-nav .nav-item:hover, .all-sites-nav.navbar .navbar-nav .nav-item:hover a.nav-link, .all-sites-nav.navbar  .navbar-nav .nav-item a.nav-link:hover {
    text-decoration: none;
    color: #fd0;
  }
  .all-sites-nav.navbar .navbar-nav .nav-item:hover .b-icon{
    text-decoration: none;
    color: #fff;
  }
  ::v-deep .navbar-nav .nav-item:hover, .navbar-nav .nav-item:hover a.nav-link, .navbar-nav .nav-item a.nav-link:hover {
    border-top: 0px;
    border-right: 0px;
    cursor: pointer;
    color: #fd0;
  }
  .logo {
    height: 55px;
    width: auto;
    padding-top: 2px;
    padding-left: 2%;
  }
  ::v-deep .navbar-nav .nav-item .nav-link:focus {
    color: #000;
    border: none;
  }
  .navbar .navbar-toggler {
    background-color: rgba(255,255,255, .9);
    border-radius: 9px;
    margin-right: 10px;
  }
  .navbar .navbar-toggler .navbar-toggler-icon {
    height: 1em;
    width: 1em;
  }
  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.2s ease;
  }
  ul {
    list-style: none;
    padding-inline-start: 0px;
  }

  @media (max-width: 445px) {
    ::v-deep .navbar .navbar-nav .nav-item a.nav-link, .btn {
      text-align: center;
    }
    .btn {
      width: 100%;
    }
  }
  @media (min-width: 445px) and (max-width: 1150px) {
    .btn {
      min-width: auto;
      padding: 5px 10px;
    }
  }
  @media (max-width: 767px) {
    .navbar-toggler-icon {
      height: 1em;
      width: 1em;
    }
  }
  @media (max-width: 991px) {
    .navbar {
      padding: .2rem .2rem .2rem .2rem;
    }
    .navbar #nav-collapse {
      background-color: #274ba7;
      padding: .2rem .2rem .2rem 1rem;
    }
    ::v-deep .navbar .navbar-nav .nav-item a.nav-link {
      box-shadow: inset 0 1px 0 rgb(255 255 255 / 10%);
      border-top: 0px;
      border-right: 0px;
      border-left: 0px;
      border-bottom: 1.5px solid;
    }
    ::v-deep .btn {
      background-color: transparent;
      color: #000;
      font-family: "Barlow", sans-serif;
      text-transform: none;
      padding: 0.5rem 0;
      letter-spacing: 2px;
      font-size: 15px;
    }
    ::v-deep .btn:hover {
      background-color: transparent;
      color: #000;
      text-decoration: underline;
    }
  }
  @media (min-width: 992px) {
    .navbar {
      padding: 0rem 1rem;
    }
    .navbar-nav.main {
      margin-left: 8%;
    }
    ::v-deep .navbar-nav.main .nav-item.first .nav-link {
      border-left: none;
    }
    ::v-deep .navbar-nav.main .nav-item .nav-link {
      padding: 3px 10px;
      border-left: 1px solid #969696;
    }
    ::v-deep .all-sites-nav.navbar .navbar-nav .nav-item .nav-link {
      margin: 3px 0;
      border-bottom: none;
    }
    ::v-deep .navbar-nav .nav-item .nav-link {
      margin: 12px 0;
      padding: 3px 2px;
      border-bottom: 2px solid transparent;
    }
    ::v-deep .navbar-nav .nav-item a.nav-link {
      -webkit-transition : border .5s linear;
      -moz-transition : border .5s linear;
      -o-transition : border .5s linear;
      transition : border .5s linear;
    }
  }
</style>
