<template>
  <div class="access-denied">
    <b-container>
      <b-row v-if="this.$route.query.error === 'user-nonsig-pending'">
        <b-col sm="12">
          <h1 class="access-denied-header">{{this.languageData.user_nonsig_pending.title}}</h1>
        </b-col>
        <b-col sm="12">
          <h2 class="access-denied-message">{{this.languageData.user_nonsig_pending.message}}</h2>
        </b-col>
      </b-row>
      <b-row v-else-if="this.$route.query.error === 'access-denied'">
        <b-col sm="12">
          <h1 class="access-denied-header">{{this.languageData.access_denied.title}}</h1>
        </b-col>
        <b-col sm="12">
          <h2 class="access-denied-message">{{this.languageData.access_denied.message}}</h2>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col sm="12">
          <h1 class="access-denied-header">{{this.languageData.access_denied.title}}</h1>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  export default {
    name: 'access-denied',
    props: {
      error: {
        type: String,
        required: false
      }
    },
    metaInfo() {
      return {
        title: this.languageData.page_title + ' | ' + this.$store.getters["appName"],
      };
    },
    computed: {
      languageData: function () {
        return this.$store.state.lang.languageData[this.$route.name.toLowerCase()][this.$store.state.lang.language];
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .access-denied {
    background-color: #fff;
    min-height: 500px;
    padding-top: 50px;
    margin-bottom: 25px;
    text-align: center;
  }
  .access-denied-header {
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    font-size: 32px;
    color: #000;
  }
  .access-denied-message {
    font-family: "Barlow", sans-serif;
    font-size: 20px;
    color: #000;
  }
</style>
